var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shelves-wrapper" },
    [
      _c(
        "div",
        { staticClass: "tableWrapper bgFFF paddingB10" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: _vm.$t("list.index"),
                  width: "80",
                },
              }),
              _vm._l(_vm.tableCols, function (item) {
                return _c("el-table-column", {
                  key: item.prop,
                  attrs: {
                    "show-overflow-tooltip": "",
                    prop: item.prop,
                    label: item.label,
                    width: item.width,
                    formatter: item.formatter,
                  },
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "min-width": "200px",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "option-wrap" }, [
                          _c(
                            "span",
                            {
                              staticClass: "hover-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.previewHandler(scope.row)
                                },
                              },
                            },
                            [_vm._v("预览")]
                          ),
                          _c(
                            "span",
                            {
                              class:
                                scope.$index != 0 ? "hover-wrap" : "dis-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.moveUpHandler(
                                    scope.row.id,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [_vm._v("上移")]
                          ),
                          _c(
                            "span",
                            {
                              class:
                                scope.$index != _vm.tableData.length - 1 &&
                                _vm.tableData.length > 1
                                  ? "hover-wrap"
                                  : "dis-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.moveDownHandler(
                                    scope.row.id,
                                    scope.$index
                                  )
                                },
                              },
                            },
                            [_vm._v("下移")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "hover-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.undercarriageHandler(scope.row)
                                },
                              },
                            },
                            [_vm._v("下架")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("preview-list", {
        attrs: { previewVisible: _vm.previewVisible, temp: _vm.temp },
        on: { closeValue: _vm.closeValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }