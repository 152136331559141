var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.searchData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                "status-icon": "",
                "label-position": "right",
                model: _vm.formInline,
              },
            },
            [
              _c("div", { staticClass: "search_box_title" }, [
                _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
              ]),
              _c("div", { staticClass: "col_box" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("searchModule.title") } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入标题" },
                          model: {
                            value: _vm.formInline.title,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "title",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("searchModule.Release_channel"),
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", size: "15" },
                            model: {
                              value: _vm.formInline.releaseType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "releaseType",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formInline.releaseType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "" },
                            }),
                            _vm._l(_vm.msgType, function (value) {
                              return _c("el-option", {
                                key: value.type,
                                attrs: { label: value.desc, value: value.type },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col_right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            _vm.pageNum = 1
                            _vm.searchData()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.search")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col_box_boder" }),
              _c("div", { staticClass: "col_box h44" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-plus" },
                        on: { click: _vm.addQuestion },
                      },
                      [_vm._v("新建问题")]
                    ),
                    _c(
                      "el-button",
                      {
                        class:
                          _vm.shelvesType == 1 ? "el-shelves" : "un-shelves",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.shelvesHandler(1)
                          },
                        },
                      },
                      [_vm._v("已上架")]
                    ),
                    _c(
                      "el-button",
                      {
                        class:
                          _vm.shelvesType == 1 ? "un-shelves" : "el-shelves",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.shelvesHandler(0)
                          },
                        },
                      },
                      [_vm._v("未上架")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "col_right mbd4" }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper bgFFF paddingB10" },
        [
          _vm.shelvesType == 1
            ? _c("shelves", {
                attrs: { loading: _vm.loading, tableData: _vm.tableData },
                on: { searchData: _vm.searchData },
              })
            : _c("un-shelves", {
                attrs: { loading: _vm.loading, tableData: _vm.tableData },
                on: {
                  updateHandler: _vm.updateHandler,
                  searchData: _vm.searchData,
                },
              }),
          _c("div", { staticClass: "pagerWrapper" }, [
            _c(
              "div",
              { staticClass: "block" },
              [
                _vm.total != 0
                  ? _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.pageNum,
                        "page-size": _vm.pageSize,
                        layout: "total, prev, pager, next, jumper",
                        total: _vm.total,
                      },
                      on: { "current-change": _vm.handleCurrentChange },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }